import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/nextjs';
import { GlobalProvider } from '../context/globalState';
import { UIProvider } from '../context/uiState';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { theme } from '../styles/theme';
import { CubeJSProvider } from '../context/cubeState';
import { ExportProvider } from '../context/exportState';
import { BasicFiltersProvider } from '../context/basicFiltersState';
import { NewSegmentProvider } from '../context/newSegmentState';
import { ReportProvider } from '../context/reportState';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import TagManager from 'react-gtm-module';
import Maintenance from './maintenance';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const cache = createCache({
  key: 'css',
  prepend: true,
});


LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY as string);

function MyApp ({ Component, pageProps }: AppProps) {

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY as string });
  }, []);

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return <Maintenance />;
  }

  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ClerkProvider {...pageProps} publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}>
            <SignedIn>
              <PostHogProvider client={posthog}>
                <UIProvider>
                  <GlobalProvider>
                    <BasicFiltersProvider>
                      <NewSegmentProvider>
                        <CubeJSProvider>
                          <ReportProvider>
                            <ExportProvider>
                              <Component {...pageProps} />
                            </ExportProvider>
                          </ReportProvider>
                        </CubeJSProvider>
                      </NewSegmentProvider>
                    </BasicFiltersProvider>
                  </GlobalProvider>
                </UIProvider>
              </PostHogProvider>
            </SignedIn>
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>
          </ClerkProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
}

export default MyApp;