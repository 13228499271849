import type { NextPage } from 'next';
import { useContext, useState, useEffect, useCallback } from 'react';
import { CubeProvider, CubeContext, CubeContextProps } from '@cubejs-client/react';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { useAuth } from '@clerk/nextjs';
import { useGlobalState } from './globalState';

export const useCubeContext = () => useContext<CubeContextProps>(CubeContext);

interface Props {
  children: React.ReactNode;
}

export const CubeJSProvider: NextPage<Props> = ({ children }) => {
  const { isLoaded, isSignedIn } = useAuth();
  const {
    setLoadingCubejs, handleLoadCompanyMetrics, handleGetMaxEffectiveTo, handleLoadPeopleMetrics, getTotalData, cubeToken, setLoadingTotalData,
    setLoadingCompanyMetrics, setLoadingPeopleMetrics
  } = useGlobalState();
  const [cubejsApi, setCubejsApi] = useState<CubejsApi | null>(null);

  const initCubejs = useCallback(async () => {
    if (cubeToken) {
      setLoadingCubejs(true);
      setLoadingCompanyMetrics(true);
      setLoadingPeopleMetrics(true);
      setCubejsApi(cubejs({
        apiUrl: process.env.NEXT_PUBLIC_CUBEJS_API_URL as string,
        headers: { Authorization: `${cubeToken}` },
      }));
      setLoadingTotalData(true);
      setLoadingCubejs(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeToken]);

  useEffect(() => {
    if (isLoaded && isSignedIn && cubeToken) {
      initCubejs();
    }
  }, [initCubejs, isSignedIn, isLoaded, cubeToken]);

  useEffect(() => {
    if (cubejsApi) {
      handleLoadCompanyMetrics(cubejsApi);
      handleLoadPeopleMetrics(cubejsApi);
      handleGetMaxEffectiveTo(cubejsApi);
      getTotalData(cubejsApi);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubejsApi]);

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      {children}
    </CubeProvider>
  );
};
