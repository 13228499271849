import { useContext, createContext, useEffect, useState, useCallback } from 'react';
import type { NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useAuth, useUser, useOrganization, useOrganizationList } from '@clerk/nextjs';
import { useUiState } from './uiState';
import axios, { AxiosResponse } from 'axios';
import { Organization } from '../types/Clerk.interfaces';
import {
  Company, Dataset, ExportRecordForPostHog, Table, TableColumn, TableMetrics, ClientResponse, Subscription, CRMIntegrationResponse, ExportConfig
} from '../types/Common.interfaces';
import { GridColDef, GridRenderCellParams, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { Box, Tooltip } from '@mui/material';
import { parseISO, format, subMonths, isAfter } from 'date-fns';
import { CubejsApi, ResultSet, Query } from '@cubejs-client/core';
import { Filter } from '../types/Cube.interfaces';
import { SyncMappings, SyncRun } from '../types/Census.interfaces';
import posthog from 'posthog-js';
import useCubejsApiWrapper from '../apiHelpers/cubejsWrapper';
import { formatDuration } from '../utils/formatDuration';
import linkedinIcon from '../public/images/linkedin-icon.svg';
import websiteIcon from '../public/images/website-link-icon.svg';
import { OrganizationResource } from '@clerk/types';

type GlobalProps = {
  isAdmin: boolean;
  token: string;
  organizations: Organization[];
  loading: boolean;
  setLoading: (value: boolean) => void;
  errorMessage: (error: any, message: string) => void;
  changeOrganization: (value: string) => void;
  adminRoleNames: string[];
  page: number;
  setPage: (value: number) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  loadingCubejs: boolean;
  setLoadingCubejs: (value: boolean) => void;
  totalCompanies: number;
  setTotalCompanies: (value: number) => void;
  totalPersons: number;
  setTotalPersons: (value: number) => void;
  integrationService: string;
  setIntegrationService: (value: string) => void;
  companyColumns: GridColDef[];
  organizationData: Company | null;
  setOrganizationData: (value: Company | null) => void;
  loadingOrganizationData: boolean;
  peopleColumns: GridColDef[];
  activeDataset: Dataset | null;
  allDatasets: Dataset[];
  changeDataset: (value: Dataset) => void;
  companyFilters: Filter[];
  peopleFilters: Filter[];
  companyDatabaseColumns: TableColumn[];
  peopleDatabaseColumns: TableColumn[];
  tables: Table[];
  setCompanyFilters: (value: Filter[]) => void;
  setPeopleFilters: (value: Filter[]) => void;
  setCompanyDatabaseColumns: (value: TableColumn[]) => void;
  setPeopleDatabaseColumns: (value: TableColumn[]) => void;
  error: boolean;
  setError: (value: boolean) => void;
  currentTab: string;
  setCurrentTab: (value: string) => void;
  defaultRowsPerPage: number;
  handleLoadCompanyMetrics: (cubejsApi: CubejsApi) => void;
  handleLoadPeopleMetrics: (cubejsApi: CubejsApi) => void;
  loadingCompanyMetrics: boolean;
  setLoadingCompanyMetrics: (value: boolean) => void;
  loadingPeopleMetrics: boolean;
  setLoadingPeopleMetrics: (value: boolean) => void;
  companyMetricsData: TableMetrics[];
  peopleMetricsData: TableMetrics[];
  metricsError: boolean;
  saveToLocalStorage: (orgName: string, table: string, columns: GridColumnVisibilityModel) => void;
  handleGetMaxEffectiveTo: (cubejsApi: CubejsApi) => void;
  openHelpPortal: () => void;
  numberFormatter: (value: any) => number | string;
  captureEventInPostHog: (eventName: string, productName?: string, exportCount?: ExportRecordForPostHog,) => void;
  captureSyncRunEventInPostHog: (eventName: string, productName: string, entity: string, recordCount: number) => void;
  captureReportEventInPostHog: (eventName: string, durationMs: number, totalCount: number, entity: string, productName?: string) => void;
  identifyUserInPostHog: (organization: OrganizationResource, activeDataset: Dataset) => void;
  companyScdColumns: { [key: string]: { type: string, displayName: string } };
  peopleScdColumns: { [key: string]: { type: string, displayName: string } };
  getPageSize: (pageSize: string, defaultPageSize: number) => number;
  updateDrawerTab: string;
  setUpdateDrawerTab: (value: string) => void;
  companyTableScdColumns: GridColDef[];
  peopleTableScdColumns: GridColDef[];
  handleRenderCell: (type: string, value: any, row?: any) => string | React.ReactElement;
  maxEffectiveToDate: string;
  setMaxEffectiveToDate: (value: string) => void;
  velocityProjectName: string;
  monthsValue: number;
  setMonthsValue: (value: number) => void;
  dateOfFirstRelease: string;
  loadingDatesOfRelease: boolean;
  loadingTotalData: boolean;
  setLoadingTotalData: (value: boolean) => void;
  getTotalData: (cubejsApi: CubejsApi) => void;
  formatColumnField: (column: TableColumn, table: string, report?: string) => string;
  clientAdmin: boolean;
  rbSupportRoleName: string;
  propertyFields: { field: string, type: string, description?: string, order: number }[];
  velocityName: string;
  crmDestinationId: number | null;
  crmSourceId: number | null;
  companySyncMappings: SyncMappings[];
  peopleSyncMappings: SyncMappings[];
  crmDestinationType: string | null;
  capitalize: (value: string) => string;
  crmSyncType: string | null;
  checkIsInitialSyncRunAfter: (lastInitialSegmentSyncRun: SyncRun | null, lastSyncRun: SyncRun | null) => boolean;
  searchEntity: string;
  setSearchEntity: (value: string) => void;
  cubeToken: string;
  setCubeToken: (value: string) => void;
  loadingCubeToken: boolean;
  subscription: Subscription | null;
  loadingCRMIntegrationData: boolean;
  exportConfigData: ExportConfig | null;
  setExportConfigData: (value: ExportConfig | null) => void;
  loadingExportConfig: boolean;
  subscriptionCanceled: boolean;
  setSubscriptionCanceled: (value: boolean) => void;
  checkOrganizationSubscription: (value: string) => void;
  checkingSubscription: boolean;
  organizationToChange: string | null,
  setOrganizationToChange: (value: string | null) => void;
  checkedSubscriptionCanceled: boolean;
  setCheckedSubscriptionCanceled: (value: boolean) => void;
}

const DEFAULT_PROPS = {
  isAdmin: false,
  token: '',
  organizations: [],
  loading: false,
  setLoading: (value: boolean) => { },
  errorMessage: (error: any, message: string) => { },
  changeOrganization: (value: string) => { },
  adminRoleNames: ['org:rb_admins', 'org:rb_support', 'org:admins'],
  page: 1,
  setPage: (value: number) => { },
  pageSize: 10,
  setPageSize: (value: number) => { },
  loadingCubejs: false,
  setLoadingCubejs: (value: boolean) => { },
  totalCompanies: 0,
  setTotalCompanies: (value: number) => { },
  totalPersons: 0,
  setTotalPersons: (value: number) => { },
  integrationService: '',
  setIntegrationService: (value: string) => { },
  companyColumns: [],
  organizationData: null,
  setOrganizationData: (value: Company | null) => { },
  loadingOrganizationData: false,
  peopleColumns: [],
  activeDataset: null,
  allDatasets: [],
  changeDataset: (value: Dataset) => { },
  companyFilters: [],
  peopleFilters: [],
  companyDatabaseColumns: [],
  peopleDatabaseColumns: [],
  tables: [],
  setCompanyFilters: (value: Filter[]) => { },
  setPeopleFilters: (value: Filter[]) => { },
  setCompanyDatabaseColumns: (value: TableColumn[]) => { },
  setPeopleDatabaseColumns: (value: TableColumn[]) => { },
  error: false,
  setError: (value: boolean) => { },
  currentTab: 'organizations',
  setCurrentTab: (value: string) => { },
  defaultRowsPerPage: 50,
  handleLoadCompanyMetrics: (cubejsApi: CubejsApi) => { },
  handleLoadPeopleMetrics: (cubejsApi: CubejsApi) => { },
  loadingCompanyMetrics: false,
  setLoadingCompanyMetrics: (value: boolean) => { },
  loadingPeopleMetrics: false,
  setLoadingPeopleMetrics: (value: boolean) => { },
  companyMetricsData: [],
  peopleMetricsData: [],
  metricsError: false,
  saveToLocalStorage: (orgName: string, table: string, columns: GridColumnVisibilityModel) => { },
  handleGetMaxEffectiveTo: (cubejsApi: CubejsApi) => { },
  openHelpPortal: () => { },
  numberFormatter: (value: any) => { return 0; },
  captureEventInPostHog: (eventName: string, productName?: string, exportedRecords?: ExportRecordForPostHog) => { },
  captureSyncRunEventInPostHog: (eventName: string, productName: string, entity: string, recordCount: number) => { },
  captureReportEventInPostHog: (eventName: string, durationMs: number, totalCount: number, entity: string, productName?: string) => { },
  identifyUserInPostHog: (organization: OrganizationResource, activeDataset: Dataset) => { },
  companyScdColumns: {},
  peopleScdColumns: {},
  getPageSize: (pageSize: string, defaultPageSize: number) => 10,
  updateDrawerTab: 'organizations',
  setUpdateDrawerTab: (value: string) => {},
  companyTableScdColumns: [],
  peopleTableScdColumns: [],
  handleRenderCell: (type: string, value: any, row?: any) => <></>,
  maxEffectiveToDate: '',
  setMaxEffectiveToDate: (value: string) => { },
  velocityProjectName: '',
  monthsValue: 3,
  setMonthsValue: (value: number) => { },
  dateOfFirstRelease: '',
  loadingDatesOfRelease: false,
  loadingTotalData: false,
  setLoadingTotalData: (value: boolean) => { },
  getTotalData: (cubejsApi: CubejsApi) => { },
  formatColumnField: (column: TableColumn, table: string, report?: string) => { return ''; },
  clientAdmin: false,
  rbSupportRoleName: 'RB Support',
  propertyFields: [],
  velocityName: '',
  crmDestinationId: null,
  crmSourceId: null,
  companySyncMappings: [],
  peopleSyncMappings: [],
  crmDestinationType: null,
  capitalize: (value: string) => { return ''; },
  crmSyncType: null,
  checkIsInitialSyncRunAfter: (lastInitialSegmentSyncRun: SyncRun | null, lastSyncRun: SyncRun | null) => { return false; },
  searchEntity: 'organizations',
  setSearchEntity: (value: string) => {},
  cubeToken: '',
  setCubeToken: (value: string) => {},
  loadingCubeToken: false,
  subscription: null,
  loadingCRMIntegrationData: false,
  exportConfigData: null,
  setExportConfigData: (value: ExportConfig | null) => {},
  loadingExportConfig: false,
  subscriptionCanceled: false,
  setSubscriptionCanceled: (value: boolean) => {},
  checkOrganizationSubscription: (value: string) => {},
  checkingSubscription: false,
  organizationToChange: null,
  setOrganizationToChange: (value: string | null) => {},
  checkedSubscriptionCanceled: false,
  setCheckedSubscriptionCanceled: (value: boolean) => {},
};

export const GlobalContext = createContext<GlobalProps>(DEFAULT_PROPS);

export const useGlobalState = () => useContext(GlobalContext);

interface Props {
  children: React.ReactNode;
}

export const GlobalProvider: NextPage<Props> = ({ children }) => {
  const { organization, membership } = useOrganization();
  const { userMemberships, setActive } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });
  const { user } = useUser();
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const { setSnackbarProps, setOpenOnboardingModal, openOnboardingModal, setOpenChangeOrgModal } = useUiState();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [cubeToken, setCubeToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingCubeToken, setLoadingCubeToken] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [adminRoleNames] = useState<string[]>(['org:rb_admins', 'org:rb_support', 'org:admins']);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loadingCubejs, setLoadingCubejs] = useState<boolean>(false);
  const [totalCompanies, setTotalCompanies] = useState<number>(0);
  const [totalPersons, setTotalPersons] = useState<number>(0);
  const [integrationService, setIntegrationService] = useState<string>('');
  const [controller] = useState<AbortController>(new AbortController());
  const [organizationData, setOrganizationData] = useState<Company | null>(null);
  const [loadingOrganizationData, setLoadingOrganizationData] = useState<boolean>(false);
  const [peopleColumns, setPeopleColumns] = useState<GridColDef[]>([]);
  const [companyColumns, setCompanyColumns] = useState<GridColDef[]>([]);
  const [activeDataset, setActiveDataset] = useState<Dataset | null>(null);
  const [allDatasets, setAllDatasets] = useState<Dataset[]>([]);
  const [companyFilters, setCompanyFilters] = useState<Filter[]>([]);
  const [peopleFilters, setPeopleFilters] = useState<Filter[]>([]);
  const [companyDatabaseColumns, setCompanyDatabaseColumns] = useState<TableColumn[]>([]);
  const [peopleDatabaseColumns, setPeopleDatabaseColumns] = useState<TableColumn[]>([]);
  const [tables, setTables] = useState<Table[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('organizations');
  const [defaultRowsPerPage, setDefaultRowsPerPage] = useState<number>(50);
  const [companyScdColumns, setCompanyScdColumns] = useState<{ [key: string]: { type: string, displayName: string } }>({});
  const [peopleScdColumns, setPeopleScdColumns] = useState<{ [key: string]: { type: string, displayName: string } }>({});
  const [companyTableScdColumns, setCompanyTableScdColumns] = useState<GridColDef[]>([]);
  const [peopleTableScdColumns, setPeopleTableScdColumns] = useState<GridColDef[]>([]);
  const [loadingCompanyMetrics, setLoadingCompanyMetrics] = useState<boolean>(false);
  const [loadingPeopleMetrics, setLoadingPeopleMetrics] = useState<boolean>(false);
  const [companyMetricsData, setCompanyMetricsData] = useState<TableMetrics[]>([]);
  const [peopleMetricsData, setPeopleMetricsData] = useState<TableMetrics[]>([]);
  const [maxEffectiveToDate, setMaxEffectiveToDate] = useState<string>('');
  const [velocityProjectName, setVelocityProjectName] = useState<string>('');
  const [metricsError, setMetricsError] = useState<boolean>(false);
  const [updateDrawerTab, setUpdateDrawerTab] = useState<string>('organizations');
  const [monthsValue, setMonthsValue] = useState<number>(3);
  const [dateOfFirstRelease, setDateOfFirstRelease] = useState<string>('');
  const [loadingDatesOfRelease, setLoadingDatesOfRelease] = useState<boolean>(false);
  const [loadingTotalData, setLoadingTotalData] = useState<boolean>(false);
  const [clientAdmin, setClientAdmin] = useState<boolean>(false);
  const [clientAdminRoleName] = useState<string>('org:admins');
  const [rbSupportRoleName] = useState<string>('org:rb_support');
  const [velocityName, setVelocityName] = useState<string>('');
  const [sourceTableMapping] = useState<{ [key: string]: string }>({
    'TAM': 'DIM_SCD_PER', 'COMPANY': 'DIM_SCD_ORG', 'COMPANY_REPORT': 'DIM_SCD_ORG', 'TAM_REPORT': 'DIM_SCD_PER',
    'TAM_REMOVED_REPORT': 'DIM_SCD_PER', 'COMPANY_REMOVED_REPORT': 'DIM_SCD_ORG'
  });
  const [crmDestinationId, setCrmDestinationId] = useState<number | null>(null);
  const [crmSourceId, setCrmSourceId] = useState<number | null>(null);
  const [companySyncMappings, setCompanySyncMappings] = useState<SyncMappings[]>([]);
  const [peopleSyncMappings, setPeopleSyncMappings] = useState<SyncMappings[]>([]);
  const [crmDestinationType, setCrmDestinationType] = useState<string | null>(null);
  const [crmSyncType, setCrmSyncType] = useState<string | null>(null);
  const [searchEntity, setSearchEntity] = useState<string>('organizations');
  const [loadingCRMIntegrationData, setLoadingCRMIntegrationData] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [subscriptionCanceled, setSubscriptionCanceled] = useState<boolean>(false);
  const [exportConfigData, setExportConfigData] = useState<ExportConfig | null>(null);
  const [loadingExportConfig, setLoadingExportConfig] = useState<boolean>(false);
  const [checkingSubscription, setCheckingSubscription] = useState<boolean>(false);
  const [organizationToChange, setOrganizationToChange] = useState<string | null>(null);
  const [checkedSubscriptionCanceled, setCheckedSubscriptionCanceled] = useState<boolean>(false);
  const [propertyFields] = useState<{ field: string, type: string, description?: string, order: number }[]>([
    {
      field: 'records_sent',
      type: 'number',
      order: 1
    },
    {
      field: 'records_updated',
      type: 'number',
      order: 2
    },
    {
      field: 'status',
      type: 'number',
      order: 3
    },
    {
      field: 'records_invalid',
      type: 'number',
      order: 4,
      // eslint-disable-next-line max-len
      description: 'Records are invalid when there is an error pulling from RevenueBase. Often times this is due to duplicates detected within the RevenueBase dataset.'
    },
    {
      field: 'records_failed',
      type: 'number',
      order: 5,
      // eslint-disable-next-line max-len
      description: 'Records fail when there is an error pushing data into the CRM. Often times this is due to locked records or duplicates detected within the CRM.'
    },
    {
      field: 'created_at',
      type: 'date',
      order: 6
    },
    {
      field: 'updated_at',
      type: 'date',
      order: 7
    },
    {
      field: 'completed_at',
      type: 'date',
      order: 8
    },
  ]);
  const router = useRouter();
  const { replace } = router;
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (membership) {
      setIsAdmin(adminRoleNames.includes(membership.role));
      setClientAdmin(membership.role === clientAdminRoleName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership]);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      getClerkToken(organization?.id || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (activeDataset) {
      (async function iife () {
        try {
          setLoadingCubeToken(true);
          const templateToken = await getToken({ template: 'revenuebase_velocity' });
          if (templateToken) {
            setCubeToken(templateToken);
            setLoadingCubejs(true);
          } else {
            setCubeToken('');
          }
        } catch (error) {
          setCubeToken('');
        } finally {
          setLoadingCubeToken(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDataset]);

  useEffect(() => {
    if (isSignedIn) {
      initializePostHog();
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (organizationData && activeDataset && subscription && subscription.plan_interval === 'month') {
      (async function iife () {
        try {
          setLoadingExportConfig(true);
          const response: AxiosResponse<ExportConfig> = await axios.get(
            `/api/clients/${organizationData}/datasets/${activeDataset.id}/export-history/count`,
            { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
          );
          setExportConfigData(response.data);
        } catch (error) {
          if (!axios.isCancel(error)) {
            setExportConfigData(null);
          }
        } finally {
          setLoadingExportConfig(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, subscription, activeDataset]);

  useEffect(() => {
    if (userMemberships.data) {
      if (userMemberships.data.length === 1 && !organization) {
        setActive && setActive({ organization: userMemberships.data[0].organization.id });
      }
      setOrganizations(userMemberships.data);
    } else {
      setOrganizations([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMemberships.data]);

  useEffect(() => {
    if (token && organization && !openOnboardingModal) {
      getOrganizationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token && organizationData) {
      (async function iife () {
        try {
          setError(false);
          setLoadingCRMIntegrationData(true);
          const response: AxiosResponse<CRMIntegrationResponse> = await axios.get(
            `/api/clients/${organization?.id}/integrations`,
            { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
          );
          setCrmDestinationId(response.data.destination?.destinationId || null);
          setCrmSourceId(response.data.source?.sourceId || null);
          setCrmDestinationType(response.data.destination?.destinationType || null);
          setCrmSyncType(response.data.source?.syncType || null);
          setPeopleSyncMappings(response.data.mappings?.peopleMappings.map((item: SyncMappings) => ({
            ...item, from: { ...item.from, data: item.from.data.toLowerCase() }}
          )) || []);
          setCompanySyncMappings(response.data.mappings?.orgMappings.map((item: SyncMappings) => ({
            ...item, from: { ...item.from, data: item.from.data.toLowerCase() }}
          )) || []);
        } catch (error) {
          setError(true);
          setCrmDestinationId(null);
          setCrmSourceId(null);
          setCrmDestinationType(null);
          setCrmSyncType(null);
          setPeopleSyncMappings([]);
          setCompanySyncMappings([]);
        } finally {
          setLoadingCRMIntegrationData(false);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, organizationData]);

  useEffect(() => {
    if (user && organization && activeDataset) {
      const handleRouteChange = () => {
        posthog?.capture('$pageview', {
          distinct_id: user?.id,
          emailAndOrganization: `${user?.primaryEmailAddress?.emailAddress} ${organization?.name}`,
          productName: activeDataset?.productName
        });
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router, user, organization, activeDataset]);

  useEffect(() => {
    if (user && organization && activeDataset) {
      identifyUserInPostHog(organization, activeDataset);
      processUserLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organization, activeDataset]);

  useEffect(() => {
    axios.interceptors.response.use(function (response: AxiosResponse) {
      return response;
    }, function (error) {
      if (!axios.isCancel(error)) {
        posthog.capture('Loading error', {
          errorMessage: error
        });
      }
      return Promise.reject(error);
    });
  }, []);

  const getClerkToken = async (clerkOrgId: string | null) => {
    try {
      setLoading(true);
      const token = await getToken({ skipCache: true });
      if (token) {
        setToken(token);
        if (clerkOrgId) {
          setLoadingOrganizationData(true);
        }
      } else {
        setToken('');
      }
    } catch (error) {
      setToken('');
    } finally {
      setLoading(false);
    }
  };

  const getOrganizationData = async () => {
    if (organization) {
      try {
        setError(false);
        const sessionId = router.query.session_id || null;
        setLoadingOrganizationData(true);
        const response: AxiosResponse<ClientResponse> = await axios.get(
          `/api/clients/${organizationToChange || organization.id}${sessionId ? `?session_id=${sessionId}` : ''}`,
          { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
        );
        setOrganizationData(response.data.client ? response.data.client : null);
        setSubscription(response.data.subscription);
        setOrganizationToChange(null);
        if (response.data.client && response.data.client.datasets && response.data.client.datasets.length) {
          setTables(response.data.client.datasets[0].tables);
          setActiveDataset(response.data.client.datasets[0]);
          setLoadingCubeToken(true);
          setVelocityProjectName(response.data.client.datasets[0].productName || '');
          setVelocityName(response.data.client.datasets[0].velocityName || organization.name);
          setAllDatasets(response.data.client.datasets);
          findColumnsAndFilters(response.data.client.datasets[0], response.data.client);
          const peopleTable = response.data.client.datasets[0].tables.find((table: Table) => table.name === 'TAM');
          if (peopleTable) {
            setDefaultRowsPerPage(peopleTable.rowsPerPage);
          }
        } else {
          setActiveDataset(null);
          setAllDatasets([]);
          setPeopleColumns([]);
          setCompanyColumns([]);
          setTotalPersons(0);
          setTotalCompanies(0);
          setTables([]);
          setVelocityProjectName('');
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          if (error.response && error.response.status === 404) {
            setOpenOnboardingModal(true);
          } else if (error.response && error.response.status === 401) {
            setOpenOnboardingModal(true);
            setSubscriptionCanceled(true);
          } else {
            setOrganizationData(null);
            setActiveDataset(null);
            setSubscription(null);
            setAllDatasets([]);
            setPeopleColumns([]);
            setCompanyColumns([]);
            setTotalPersons(0);
            setTotalCompanies(0);
            setTables([]);
            setVelocityProjectName('');
            setVelocityName('');
            setError(true);
          }
        }
      } finally {
        setLoadingOrganizationData(false);
      }
    }
  };

  const errorMessage = (error: any, message: string) => {
    if (axios.isAxiosError(error) && error.response) {
      setSnackbarProps({ open: true, message: error.response.data.message, type: 'error' });
    } else {
      setSnackbarProps({ open: true, message: message, type: 'error' });
    }
  };

  const changeOrganization = (orgId: string) => {
    if (organization?.id !== orgId && setActive) {
      setToken('');
      setError(false);
      setActive({ organization: orgId });
      replace('/');
      setOrganizationData(null);
      setActiveDataset(null);
      setAllDatasets([]);
      setPeopleColumns([]);
      setCompanyColumns([]);
      setCompanyFilters([]);
      setPeopleFilters([]);
      setPeopleScdColumns({});
      setCompanyScdColumns({});
      setCompanyTableScdColumns([]);
      setPeopleTableScdColumns([]);
      setTotalCompanies(0);
      setTotalPersons(0);
      setMonthsValue(3);
      setCompanyDatabaseColumns([]);
      setPeopleDatabaseColumns([]);
      setVelocityProjectName('');
      setVelocityName('');
      setCrmDestinationId(null);
      setCrmSourceId(null);
      setCrmDestinationType(null);
      setCrmSyncType(null);
      setSubscription(null);
      setPeopleSyncMappings([]);
      setCompanySyncMappings([]);
      setCheckedSubscriptionCanceled(false);
      getClerkToken(orgId);
    }
  };

  const checkOrganizationSubscription = async (orgId: string) => {
    try {
      setCheckingSubscription(true);
      // eslint-disable-next-line no-unused-vars
      const response: AxiosResponse<{ message: string }> = await axios.get(
        `/api/clients/${orgId}/subscriptions`,
        { headers: { 'Content-Type': 'application/json' }, signal: controller.signal }
      );
      setCheckedSubscriptionCanceled(false);
      setCheckingSubscription(false);
      setOpenChangeOrgModal(false);
      changeOrganization(orgId);
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (error.response && error.response.status === 404) {
          setOpenChangeOrgModal(true);
          setCheckedSubscriptionCanceled(true);
        }
      }
      setCheckingSubscription(false);
    }
  };

  const getLink = (row: { [key: string]: any }, column: string): string => {
    const key = Object.keys(row).length ? Object.keys(row).sort()[0].split('.')[0] : null;
    if (key) {
      const findLink = row[`${key}.${column}`];
      return findLink ? findLink : '';
    } else {
      return '';
    }
  };

  const handleOpenLink = (link: string) => {
    if (window && typeof window !== 'undefined' && link) {
      window.open(link);
    }
  };

  const isLink = (type: string): boolean => {
    return type.startsWith('link');
  };

  const isMultiLink = (type: string): boolean => {
    return type.startsWith('multi link');
  };

  const isUrl = (type: string): boolean => {
    return type.startsWith('url');
  };

  const handleRenderCell = (type: string, value: any, row?: any): string | React.ReactElement => {
    if (isLink(type)) {
      return value ? (
        <a
          href={value}
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: '#00b5b0', textDecoration: 'none' }}
        >
          {value}
        </a>
      ) : '';
    }
    if (isMultiLink(type)) {
      if (value) {
        const linkedinLink = getLink(row, 'linkedin_url_org');
        const websiteLink = getLink(row, 'website_org');

        return (
          <Box className='u-flex u-flex-justify-start u-flex-align-center'>
            <Tooltip title={getLink(row, 'linkedin_url_org')} placement='bottom'>
              <Box
                className='u-mr-12 u-flex u-flex-justify-start u-flex-align-center u-cursor-pointer u-w-20-px'
                onClick={() => handleOpenLink(linkedinLink)}
                sx={{ minWidth: '20px' }}
              >
                <Image src={linkedinIcon} width={20} height={20} alt='LinkedIn icon' />
              </Box>
            </Tooltip>
            {websiteLink ? (
              <Tooltip title={getLink(row, 'website_org')} placement='bottom'>
                <Box
                  className='u-mr-12 u-flex u-flex-justify-start u-flex-align-center u-cursor-pointer u-w-20-px'
                  onClick={() => handleOpenLink(websiteLink)}
                  sx={{ minWidth: '20px' }}
                >
                  <Image src={websiteIcon} width={20} height={20} alt='Website icon' />
                </Box>
              </Tooltip>
            ) : (
              <Box
                className='u-mr-12 u-flex u-flex-justify-start u-flex-align-center u-w-20-px'
                sx={{ minWidth: '20px' }}
              ></Box>
            )}
            {value}
          </Box>
        );
      } else {
        return '';
      }
    }
    if (isUrl(type)) {
      if (value && value.split('-*-')[1]) {
        return (
          <a
            href={!value.split('-*-')[1].startsWith('https://') && !value.split('-*-')[1].startsWith('http://')
              ? `https://${value.split('-*-')[1]}` : value.split('-*-')[1]}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#00b5b0', textDecoration: 'none' }}
          >
            {value.split('-*-')[0]}
          </a>
        );
      } else {
        return value ? value.split('-*-')[0] : '';
      }
    }
    switch (type) {
      case 'currency':
        return value  ? formatNumber(value) : '';
      case 'date':
        return value ? format(parseISO(value), 'MM/dd/yyyy') : '';
      case 'boolean':
        return value ? 'True' : value === null ? '' : 'False';
      case 'string':
        return value ? typeof value === 'object' ? value.join(', ') : value : '';
      case 'number':
        return value ? value : '';
      default: return value ? value : '';
    }
  };

  const formatNumber = (value: string): string => {
    return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatColumnField = useCallback((column: TableColumn, table: string, report?: string): string => {
    const sameSource = column.sourceTable === sourceTableMapping[table.toUpperCase()];
    const otherSource = table.toLowerCase().includes('tam') ?
      table.toUpperCase().replace('TAM', 'COMPANY') : table.toUpperCase().replace('COMPANY', 'TAM');
    const modifiedColumnName = column.columnName.toLowerCase();
    return sameSource ? `${table.toUpperCase()}${report ? report.toUpperCase() : ''}.${ modifiedColumnName}` : `${otherSource}.${modifiedColumnName}`;
  }, [sourceTableMapping]);

  const findColumnsAndFilters = (dataset: Dataset, org: Company) => {
    const findCompanyTable = dataset.tables.find((table: Table) => table.name === 'COMPANY');
    if (findCompanyTable && findCompanyTable.tableColumns.length) {
      const foundedTableColumns = [...findCompanyTable.tableColumns];
      const columns: GridColDef[] = [];
      const filters: Filter[] = [];
      const orgScdColumns: GridColDef[] = [];
      const orgScdTableColumns: { [key: string]: { type: string, displayName: string } } = {};
      foundedTableColumns.forEach((column: TableColumn) => {
        const fieldValue = formatColumnField(column, findCompanyTable.name);
        columns.push({
          field: fieldValue,
          headerName: column.displayName,
          width: column.displayWidth,
          renderCell: (params: GridRenderCellParams) => handleRenderCell(column.columnType, params.value, params.row),
          cellClassName: column.isCustom ? 'custom-cell' : '',
          headerClassName: column.isCustom ? 'custom-header' : 'table-header',
          pinnable: false
        });
        if (column.isFilter) {
          filters.push({
            title: column.displayName,
            value: fieldValue,
            type: column.filterType,
            isCustom: column.isCustom,
            isDisplayed: modifyFilters(fieldValue, column.isDisplayed, 'company', org),
            columnType: column.columnType,
            searchColumn: column.searchTableName && column.searchTableColumnName ? `${column.searchTableName}.${column.searchTableColumnName}` : null,
            showNulls: column.showNulls,
          });
        }
        if (column.scdFlag) {
          orgScdColumns.push({
            field: `COMPANY_REPORT.old_${column.columnName.toLowerCase()}`,
            headerName: column.displayName,
            width: column.displayWidth,
            renderCell: (params: GridRenderCellParams) => handleRenderCell(column.columnType, params.value, params.row),
            headerClassName: column.isCustom ? 'custom-header' : 'table-header',
            cellClassName: column.isCustom ? 'custom-cell' : '',
            pinnable: false
          });
          orgScdTableColumns[`COMPANY_REPORT.old_${column.columnName as string}`] = { displayName: column.displayName, type: column.columnType };
        }
      });
      setCompanyColumns(columns);
      setCompanyFilters(filters);
      setCompanyScdColumns(orgScdTableColumns);
      setCompanyTableScdColumns(orgScdColumns);
      modifyDatabaseColumns(foundedTableColumns, setCompanyDatabaseColumns, 'company', org);
    } else {
      setCompanyDatabaseColumns([]);
      setCompanyColumns([]);
      setCompanyFilters([]);
      setCompanyScdColumns({});
      setCompanyTableScdColumns([]);
    }
    const findPeopleTable = dataset.tables.find((table: Table) => table.name === 'TAM');
    if (findPeopleTable && findPeopleTable.tableColumns.length) {
      const foundedPerTableColumns = [...findPeopleTable.tableColumns];
      const columns: GridColDef[] = [];
      const filters: Filter[] = [];
      const perScdColumns: GridColDef[] = [];
      const perScdTableColumns: { [key: string]: { type: string, displayName: string } } = {};
      foundedPerTableColumns.forEach((column: TableColumn) => {
        const fieldValue = formatColumnField(column, findPeopleTable.name);
        columns.push({
          field: fieldValue,
          headerName: column.displayName,
          width: column.displayWidth,
          renderCell: (params: GridRenderCellParams) => handleRenderCell(column.columnType, params.value, params.row),
          cellClassName: column.isCustom ? 'custom-cell' : '',
          headerClassName: column.isCustom ? 'custom-header' : 'table-header',
          pinnable: false
        });
        if (column.isFilter) {
          filters.push({
            title: column.displayName,
            value: fieldValue,
            type: column.filterType,
            isCustom: column.isCustom,
            isDisplayed: modifyFilters(fieldValue, column.isDisplayed, 'tam', org),
            columnType: column.columnType,
            searchColumn: column.searchTableName && column.searchTableColumnName ? `${column.searchTableName}.${column.searchTableColumnName}` : null,
            showNulls: column.showNulls,
          });
        }
        if (column.scdFlag) {
          perScdColumns.push({
            field: `TAM_REPORT.old_${column.columnName.toLowerCase()}`,
            headerName: column.displayName,
            width: column.displayWidth,
            renderCell: (params: GridRenderCellParams) => handleRenderCell(column.columnType, params.value, params.row),
            headerClassName: column.isCustom ? 'custom-header' : 'table-header',
            cellClassName: column.isCustom ? 'custom-cell' : '',
            pinnable: false
          });
          perScdTableColumns[`TAM_REPORT.old_${column.columnName as string}`] = { displayName: column.displayName, type: column.columnType };
        }
      });
      setPeopleColumns(columns);
      setPeopleFilters(filters);
      setPeopleScdColumns(perScdTableColumns);
      setPeopleTableScdColumns(perScdColumns);
      modifyDatabaseColumns(foundedPerTableColumns, setPeopleDatabaseColumns, 'tam', org);
    } else {
      setPeopleDatabaseColumns([]);
      setPeopleColumns([]);
      setPeopleFilters([]);
      setPeopleScdColumns({});
      setPeopleTableScdColumns([]);
    }
  };

  const modifyDatabaseColumns = (columns: TableColumn[], setColumns: (value: TableColumn[]) => void, table: string, org: Company) => {
    try {
      setColumns(columns.map((item: TableColumn) => {
        if (org && localStorage.hasOwnProperty(org.name)) {
          const data = JSON.parse(localStorage[org.name]);
          const localStorageTable = data[table];
          if (localStorageTable && localStorageTable.hasOwnProperty(`${table.toUpperCase()}.${item.columnName}`)) {
            return { ...item, isDisplayed: localStorageTable[`${table.toUpperCase()}.${item.columnName}`] };
          } else {
            return item;
          }
        } else {
          return item;
        }
      }));
    } catch (error) {
      setColumns([]);
    }
  };

  const modifyFilters = (columnName: string, value: boolean, table: string, org: Company): boolean => {
    if (localStorage.hasOwnProperty(org.name)) {
      try {
        const data = JSON.parse(localStorage[org.name]);
        if (data) {
          const tableData = data[table];
          if (tableData && tableData.hasOwnProperty(columnName)) {
            return tableData[columnName];
          } else {
            return value;
          }
        } else {
          return value;
        }
      } catch (error) {
        return value;
      }
    } else {
      return value;
    }
  };

  const changeDataset = (newDataset: Dataset) => {
    if (organizationData) {
      setActiveDataset(newDataset);
      setVelocityProjectName(newDataset.productName || '');
      setVelocityName(newDataset.velocityName ? newDataset.velocityName : organization ? organization.name : '');
      findColumnsAndFilters(newDataset, organizationData);
      const peopleTable = newDataset.tables.find((table: Table) => table.name === 'TAM');
      if (peopleTable) {
        setDefaultRowsPerPage(peopleTable.rowsPerPage);
      }
      setPeopleMetricsData([]);
      setCompanyMetricsData([]);
      setTables(newDataset.tables);
      router.push('/');
    }
  };

  const generateQuery = (cube: string, columnType: string, day: string): Query => {
    return {
      dimensions: [`${cube.toUpperCase()}_REPORT.${columnType}`],
      measures: [`${cube.toUpperCase()}_REPORT.countDistinct`],
      timeDimensions: [
        {
          dimension: `${cube.toUpperCase()}_REPORT.${day}`,
          granularity: 'day',
        }
      ],
      segments: [`${cube.toUpperCase()}_REPORT.is${columnType}`]
    };
  };

  const handleLoadPeopleMetrics = async (cubejsApi: CubejsApi) => {
    try {
      setLoadingPeopleMetrics(true);
      setMetricsError(false);
      const peopleTotals = await cubejsApiWrapper(
        {
          dimensions: ['TAM_STATS._effective_from', 'TAM_STATS.total_count'],
        },
        {},
        cubejsApi
      );
      const addedQuery: Query = generateQuery('tam', '_added', '_effective_from');
      const updatedQuery: Query = generateQuery('tam', '_updated', '_effective_from');
      const removedQuery: Query = generateQuery('tam', '_removed', '_effective_to');
      const [addedResultSet, updatedResultSet, removedResultSet] = await Promise.all([
        cubejsApiWrapper(addedQuery, {}, cubejsApi),
        cubejsApiWrapper(updatedQuery, {}, cubejsApi),
        cubejsApiWrapper(removedQuery, {}, cubejsApi)
      ]);
      const groupedData: any[] = [];
      addedResultSet.tablePivot().map((item: any) => {
        groupedData.push({
          'TAM_REPORT._effective_from.day': item['TAM_REPORT._effective_from.day'],
          'TAM_REPORT._added': item['TAM_REPORT.countDistinct']
        });
      });
      updatedResultSet.tablePivot().map((item: any) => {
        const findData = groupedData.find(
          (value: any) => value['TAM_REPORT._effective_from.day'] === item['TAM_REPORT._effective_from.day']
        );
        if (findData) {
          findData['TAM_REPORT._updated'] = item['TAM_REPORT.countDistinct'];
        } else {
          groupedData.push({
            'TAM_REPORT._effective_from.day': item['TAM_REPORT._effective_from.day'],
            'TAM_REPORT._updated': item['TAM_REPORT.countDistinct']
          });
        }
      });
      removedResultSet.tablePivot().map((item: any) => {
        const findData = groupedData.find(
          (value: any) => value['TAM_REPORT._effective_from.day'] === item['TAM_REPORT._effective_to.day']
        );
        if (findData) {
          findData['TAM_REPORT._removed'] = item['TAM_REPORT.countDistinct'];
        } else {
          groupedData.push({
            'TAM_REPORT._effective_from.day': item['TAM_REPORT._effective_from.day'],
            'TAM_REPORT._removed': item['TAM_REPORT.countDistinct']
          });
        }
      });
      const newPeopleData = makeAndSortGraphData(groupedData, 'tam', peopleTotals.tablePivot());
      setPeopleMetricsData(newPeopleData);
    } catch (error) {
      setPeopleMetricsData([]);
      setMetricsError(true);
    } finally {
      setLoadingPeopleMetrics(false);
    }
  };

  const handleLoadCompanyMetrics = async (cubejsApi: CubejsApi) => {
    try {
      setLoadingCompanyMetrics(true);
      setMetricsError(false);
      const companyTotals = await cubejsApiWrapper(
        {
          dimensions: ['COMPANY_STATS._effective_from', 'COMPANY_STATS.total_count'],
        },
        {},
        cubejsApi
      );
      const addedQuery: Query = generateQuery('company', '_added', '_effective_from');
      const updatedQuery: Query = generateQuery('company', '_updated', '_effective_from');
      const removedQuery: Query = generateQuery('company', '_removed', '_effective_to');
      const [addedResultSet, updatedResultSet, removedResultSet] = await Promise.all([
        cubejsApiWrapper(addedQuery, {}, cubejsApi),
        cubejsApiWrapper(updatedQuery, {}, cubejsApi),
        cubejsApiWrapper(removedQuery, {}, cubejsApi)
      ]);
      const groupedData: any[] = [];
      addedResultSet.tablePivot().map((item: any) => {
        groupedData.push({
          'COMPANY_REPORT._effective_from.day': item['COMPANY_REPORT._effective_from.day'],
          'COMPANY_REPORT._added': item['COMPANY_REPORT.countDistinct']
        });
      });
      updatedResultSet.tablePivot().map((item: any) => {
        const findData = groupedData.find(
          (value: any) => value['COMPANY_REPORT._effective_from.day'] === item['COMPANY_REPORT._effective_from.day']
        );
        if (findData) {
          findData['COMPANY_REPORT._updated'] = item['COMPANY_REPORT.countDistinct'];
        } else {
          groupedData.push({
            'COMPANY_REPORT._effective_from.day': item['COMPANY_REPORT._effective_from.day'],
            'COMPANY_REPORT._updated': item['COMPANY_REPORT.countDistinct']
          });
        }
      });
      removedResultSet.tablePivot().map((item: any) => {
        const findData = groupedData.find(
          (value: any) => value['COMPANY_REPORT._effective_from.day'] === item['COMPANY_REPORT._effective_to.day']
        );
        if (findData) {
          findData['COMPANY_REPORT._removed'] = item['COMPANY_REPORT.countDistinct'];
        } else {
          groupedData.push({
            'COMPANY_REPORT._effective_from.day': item['COMPANY_REPORT._effective_from.day'],
            'COMPANY_REPORT._removed': item['COMPANY_REPORT.countDistinct']
          });
        }
      });
      const newCompanyData = makeAndSortGraphData(groupedData, 'company', companyTotals.tablePivot());
      setCompanyMetricsData(newCompanyData);
    } catch (error) {
      setCompanyMetricsData([]);
      setMetricsError(true);
    } finally {
      setLoadingCompanyMetrics(false);
    }
  };

  const handleGetMaxEffectiveTo = async (cubejsApi: CubejsApi) => {
    try {
      setLoadingDatesOfRelease(true);
      const effectiveToQuery: Query = {
        dimensions: ['COMPANY_REPORT._effective_from'],
        order: [['COMPANY_REPORT._effective_from', 'desc']],
        filters: [{ member: 'COMPANY_REPORT._effective_from', operator: 'set' }]
      };
      const effectiveToResultSet: ResultSet<any> = await cubejsApiWrapper(effectiveToQuery, {}, cubejsApi);
      const effectiveToData = effectiveToResultSet.tablePivot();
      if (effectiveToData.length) {
        setMaxEffectiveToDate(effectiveToData[0]['COMPANY_REPORT._effective_from'] as string);
        setDateOfFirstRelease(effectiveToData[effectiveToData.length - 1]['COMPANY_REPORT._effective_from'] as string);
      }
    } catch (error) {
      setMaxEffectiveToDate('');
      setDateOfFirstRelease('');
    } finally {
      setLoadingDatesOfRelease(false);
    }
  };

  const getTotalData = async (cubejsApi: CubejsApi) => {
    try {
      setLoadingTotalData(true);
      const resultSet: ResultSet<any> = await cubejsApiWrapper(
        {
          measures: ['COMPANY.countDistinct'],
        },
        {},
        cubejsApi
      );
      const personResultSet: ResultSet<any> = await cubejsApiWrapper(
        {
          measures: ['TAM.countDistinct'],
        },
        {},
        cubejsApi
      );
      setTotalCompanies(+resultSet.tablePivot()[0]['COMPANY.countDistinct']);
      setTotalPersons(+personResultSet.tablePivot()[0]['TAM.countDistinct']);
    } catch (error) {
      setTotalCompanies(0);
      setTotalPersons(0);
    } finally {
      setLoadingTotalData(false);
    }
  };

  const makeAndSortGraphData = (
    data: { [key: string]: string | number | boolean }[], type: string, totalData: { [key: string]: number | string | boolean }[]
  ): TableMetrics[] => {
    const newData = data.reduce((prevValue: TableMetrics[], currentValue: { [key: string]: string | number | boolean }) => {
      const obj: TableMetrics = {
        date: (currentValue[`${type.toUpperCase()}_REPORT._effective_from.day`] as string).slice(0, 10),
        total: 0,
        added: (currentValue[`${type.toUpperCase()}_REPORT._added`] as string) ? +currentValue[`${type.toUpperCase()}_REPORT._added`] : 0,
        updated: (currentValue[`${type.toUpperCase()}_REPORT._updated`] as string) ? +currentValue[`${type.toUpperCase()}_REPORT._updated`] : 0,
        removed: (currentValue[`${type.toUpperCase()}_REPORT._removed`] as string) ? +currentValue[`${type.toUpperCase()}_REPORT._removed`] : 0,
      };
      const dateExists = prevValue.find((item: TableMetrics) => (
        item.date === (currentValue[`${type.toUpperCase()}_REPORT._effective_from.day`] as string).slice(0, 10)
      ));
      if (dateExists) {
        if ((currentValue[`${type.toUpperCase()}_REPORT._added`] as string)) {
          dateExists.added = +currentValue[`${type.toUpperCase()}_REPORT._added`];
        } else if ((currentValue[`${type.toUpperCase()}_REPORT._removed`] as string)) {
          dateExists.removed = +currentValue[`${type.toUpperCase()}_REPORT._removed`];
        } else if ((currentValue[`${type.toUpperCase()}_REPORT._updated`] as string)) {
          dateExists.updated = +currentValue[`${type.toUpperCase()}_REPORT._updated`];
        }
      } else {
        prevValue.push(obj);
      }
      return prevValue;
    }, []);
    const sorted = newData
      .sort((a: TableMetrics, b: TableMetrics) => +(new Date(a.date)) - +(new Date(b.date)))
      .reduce((prevValue: TableMetrics[], currentValue: TableMetrics, currentIndex: number, array: TableMetrics[]) => {
        const findTotalData = totalData.find((value: { [key: string]: number | string | boolean }) => (
          (value[`${type.toUpperCase()}_STATS._effective_from`] as string).slice(0, 10) === currentValue.date
        ));
        currentValue.total = findTotalData ? +findTotalData[`${type.toUpperCase()}_STATS.total_count`] : 0;
        prevValue.push(currentValue);
        return prevValue;
      }, []);
    sorted.unshift({
      total: 0,
      added: 0,
      updated: 0,
      removed: 0,
      date: format(subMonths(new Date(sorted[0].date), 1), 'yyyy-MM-dd')
    });
    return sorted;
  };

  const openHelpPortal = () => {
    captureEventInPostHog('Help clicked', activeDataset?.productName);
    window.open('https://revenuebase.atlassian.net/servicedesk/customer/portal/1');
  };

  const saveToLocalStorage = (orgName: string, table: string, columns: GridColumnVisibilityModel) => {
    try {
      if (localStorage[orgName]) {
        const previousData = JSON.parse(localStorage[orgName]);
        localStorage[orgName] = JSON.stringify({
          ...previousData,
          [table]: columns,
        });
      } else {
        localStorage[orgName] = JSON.stringify({
          [table]: columns,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error with parsing localStorage ' + error);
    }
  };

  const isNumber = (value: any): boolean => {
    return /^-?\d+$/.test(value);
  };

  const numberFormatter = (num: number): string | number =>  {
    if (isNumber(num)) {
      if (num > 999999) {
        return ((Math.abs(num) / 1000000).toFixed(2)) + 'm';
      } else if (num > 999) {
        return ((Math.abs(num) / 1000).toFixed(2)) + 'k';
      } else {
        return Math.abs(num);
      }
    } else {
      return 0;
    }
  };

  const initializePostHog = () => {
    const postHogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY_SELF_SERVE;
    if (postHogKey && typeof window !== 'undefined') {
      posthog.init(postHogKey, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          }
        },
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing();
        }
      });
    }
  };

  const identifyUserInPostHog = (organization: OrganizationResource, activeDataset: Dataset) => {
    posthog.identify(user!.id, {
      name: `${user!.firstName} ${user!.lastName}`,
      email: user!.primaryEmailAddress?.emailAddress,
      organization: organization.name,
      organizationId: organization.id,
      emailAndOrganization: `${user!.primaryEmailAddress?.emailAddress} ${organization.name}`,
      velocityName: activeDataset.productName || '1:1',
      trialAccount: subscription ? subscription.status === 'trialing' : false,
    });
  };

  const captureEventInPostHog = (eventName: string, productName?: string, exportedRecords?: ExportRecordForPostHog) => {
    if (exportedRecords?.name && exportedRecords?.count) {
      posthog.capture(eventName, {
        distinct_id: user?.id,
        emailAndOrganization: `${user?.primaryEmailAddress?.emailAddress} ${organization?.name}`,
        exportedRecords: `${exportedRecords.name} ${exportedRecords.count}`,
        productName: productName || '1:1'
      });
    } else {
      posthog.capture(eventName, {
        distinct_id: user?.id,
        emailAndOrganization: `${user?.primaryEmailAddress?.emailAddress} ${organization?.name}`,
        productName: productName || '1:1'
      });
    }
  };

  const captureSyncRunEventInPostHog = (eventName: string, productName: string, entity: string, recordCount: number) => {
    posthog.capture(eventName, {
      distinct_id: user?.id,
      emailAndOrganization: `${user?.primaryEmailAddress?.emailAddress} ${organization?.name}`,
      productName: productName || '1:1',
      entity: entity,
      recordCount: recordCount
    });
  };

  const captureReportEventInPostHog = (eventName: string, durationMs: number, totalCount: number, tableName: string, productName?: string) => {
    const formattedDuration = formatDuration(durationMs);
    posthog.capture(eventName, {
      distinct_id: user?.id,
      emailAndOrganization: `${user?.primaryEmailAddress?.emailAddress} ${organization?.name}`,
      duration: formattedDuration,
      recordCount: `${totalCount} ${tableName}`,
      productName: productName || '1:1'
    });
  };

  const processUserLogin = () => {
    try {
      const userLoginsCountKey = 'loginsCount';
      if (localStorage.getItem(userLoginsCountKey)) {
        if (!user?.lastSignInAt) {
          posthog.capture('Login', {
            distinct_id: user?.id,
            emailAndOrganization: `${user?.primaryEmailAddress?.emailAddress} ${organization?.name}`,
            productName: activeDataset?.productName
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error with parsing localStorage ' + error);
    }
  };

  const getPageSize = (pageSize: string, defaultPageSize: number): number => {
    const parsedPageSize = parseInt(pageSize, 10);
    return parsedPageSize > 0 ? parsedPageSize : defaultPageSize;
  };

  const capitalize = (value: string): string => {
    return value[0].toUpperCase() + value.slice(1);
  };

  const checkIsInitialSyncRunAfter = (lastInitialSegmentSyncRun: SyncRun | null, lastSyncRun: SyncRun | null): boolean => {
    if (lastInitialSegmentSyncRun && lastSyncRun) {
      return isAfter(new Date(lastInitialSegmentSyncRun.created_at), new Date(lastSyncRun.created_at));
    } else if (lastInitialSegmentSyncRun && !lastSyncRun) {
      return true;
    } else {
      return false;
    }
  };


  return (
    <GlobalContext.Provider
      value={{
        isAdmin,
        organizations,
        token,
        loading,
        setLoading,
        errorMessage,
        changeOrganization,
        adminRoleNames,
        page,
        setPage,
        pageSize,
        setPageSize,
        loadingCubejs,
        setLoadingCubejs,
        totalCompanies,
        setTotalCompanies,
        totalPersons,
        setTotalPersons,
        integrationService,
        setIntegrationService,
        companyColumns,
        organizationData,
        setOrganizationData,
        loadingOrganizationData,
        peopleColumns,
        activeDataset,
        allDatasets,
        changeDataset,
        companyFilters,
        peopleFilters,
        companyDatabaseColumns,
        peopleDatabaseColumns,
        tables,
        setCompanyFilters,
        setPeopleFilters,
        setCompanyDatabaseColumns,
        setPeopleDatabaseColumns,
        error,
        setError,
        currentTab,
        setCurrentTab,
        defaultRowsPerPage,
        handleLoadCompanyMetrics,
        handleLoadPeopleMetrics,
        loadingCompanyMetrics,
        setLoadingCompanyMetrics,
        loadingPeopleMetrics,
        setLoadingPeopleMetrics,
        companyMetricsData,
        peopleMetricsData,
        metricsError,
        saveToLocalStorage,
        handleGetMaxEffectiveTo,
        openHelpPortal,
        numberFormatter,
        captureEventInPostHog,
        captureSyncRunEventInPostHog,
        captureReportEventInPostHog,
        identifyUserInPostHog,
        companyScdColumns,
        peopleScdColumns,
        getPageSize,
        updateDrawerTab,
        setUpdateDrawerTab,
        companyTableScdColumns,
        peopleTableScdColumns,
        handleRenderCell,
        maxEffectiveToDate,
        setMaxEffectiveToDate,
        velocityProjectName,
        monthsValue,
        setMonthsValue,
        dateOfFirstRelease,
        loadingDatesOfRelease,
        loadingTotalData,
        setLoadingTotalData,
        getTotalData,
        formatColumnField,
        clientAdmin,
        rbSupportRoleName,
        propertyFields,
        velocityName,
        crmDestinationId,
        crmSourceId,
        companySyncMappings,
        peopleSyncMappings,
        crmDestinationType,
        capitalize,
        crmSyncType,
        checkIsInitialSyncRunAfter,
        searchEntity,
        setSearchEntity,
        cubeToken,
        setCubeToken,
        loadingCubeToken,
        subscription,
        loadingCRMIntegrationData,
        exportConfigData,
        setExportConfigData,
        loadingExportConfig,
        subscriptionCanceled,
        setSubscriptionCanceled,
        checkOrganizationSubscription,
        checkingSubscription,
        organizationToChange,
        setOrganizationToChange,
        checkedSubscriptionCanceled,
        setCheckedSubscriptionCanceled,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
