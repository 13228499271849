import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { createContext, useState, useContext, useEffect } from 'react';
import { Toast } from '../types/Common.interfaces';
import { AlertColor } from '@mui/material';
import { useOrganization, useAuth } from '@clerk/nextjs';

type UIProps = {
  openMenu: boolean;
  setOpenMenu: (value: boolean) => void;
  menuWidth: number;
  setMenuWidth: (value: number) => void;
  duration: number;
  openMenuWidth: number;
  showMenuItems: boolean;
  setShowMenuItems: (value: boolean) => void;
  showLogo: boolean;
  setShowLogo: (value: boolean) => void;
  snackbarProps: Toast;
  setSnackbarProps: (value: Toast) => void;
  displayToast: (message: string, type: AlertColor, customIcon?: boolean) => void;
  closedMenuWidth: number;
  isSideMenuOpen: boolean;
  isExportOpen: boolean;
  setIsExportOpen: (value: boolean) => void;
  isExportDropdownOpen: boolean;
  setIsExportDropdownOpen: (value: boolean) => void;
  openTamDrawer: boolean;
  setOpenTamDrawer: (value: boolean) => void;
  openPersonaDefinitionDrawer: boolean;
  setOpenPersonaDefinitionDrawer: (value: boolean) => void;
  openExportHistoryDrawer: boolean;
  setOpenExportHistoryDrawer: (value: boolean) => void;
  toolbarHeight: number;
  setToolbarHeight: (value: number) => void;
  searchBarHeight: number;
  setSearchBarHeight: (value: number) => void;
  openCustomInsightsDrawer: boolean;
  setOpenCustomInsightsDrawer: (value: boolean) => void;
  openUpdatesSummaryDrawer: boolean;
  setOpenUpdatesSummaryDrawer: (value: boolean) => void;
  openColumnsDrawer: boolean;
  setOpenColumnsDrawer: (value: boolean) => void;
  filtersBarHeight: number;
  setFiltersBarHeight: (value: number) => void;
  openPeopleDrawer: boolean;
  setOpenPeopleDrawer: (value: boolean) => void;
  openSettingsModal: boolean;
  setOpenSettingsModal: (value: boolean) => void;
  openOnboardingModal: boolean;
  setOpenOnboardingModal: (value: boolean) => void;
  openWelcomeModal: boolean;
  setOpenWelcomeModal: (value: boolean) => void;
  openChangeOrgModal: boolean;
  setOpenChangeOrgModal: (value: boolean) => void;
};

const DEFAULT_PROPS = {
  openMenu: true,
  setOpenMenu: (value: boolean) => {},
  menuWidth: 250,
  setMenuWidth: (value: number) => {},
  duration: 500,
  openMenuWidth: 250,
  showMenuItems: true,
  setShowMenuItems: (value: boolean) => {},
  showLogo: true,
  setShowLogo: (value: boolean) => {},
  snackbarProps: { open: false, message: '', type: undefined },
  setSnackbarProps: (value: Toast) => {},
  displayToast: (message: string, type: AlertColor, customIcon?: boolean) => {},
  closedMenuWidth: 80,
  isSideMenuOpen: true,
  isExportOpen: false,
  setIsExportOpen: (value: boolean) => {},
  isExportDropdownOpen: false,
  setIsExportDropdownOpen: (value: boolean) => {},
  openTamDrawer: false,
  setOpenTamDrawer: (value: boolean) => {},
  openPersonaDefinitionDrawer: false,
  setOpenPersonaDefinitionDrawer: (value: boolean) => {},
  openExportHistoryDrawer: false,
  setOpenExportHistoryDrawer: (value: boolean) => {},
  toolbarHeight: 0,
  setToolbarHeight: (value: number) => {},
  searchBarHeight: 0,
  setSearchBarHeight: (value: number) => {},
  openCustomInsightsDrawer: false,
  setOpenCustomInsightsDrawer: (value: boolean) => {},
  openUpdatesSummaryDrawer: false,
  setOpenUpdatesSummaryDrawer: (value: boolean) => {},
  openColumnsDrawer:  false,
  setOpenColumnsDrawer: (value: boolean) => {},
  filtersBarHeight: 0,
  setFiltersBarHeight: (value: number) => {},
  openPeopleDrawer: false,
  setOpenPeopleDrawer: (value: boolean) => {},
  openSettingsModal: false,
  setOpenSettingsModal: (value: boolean) => {},
  openOnboardingModal: false,
  setOpenOnboardingModal: (value: boolean) => {},
  openWelcomeModal: false,
  setOpenWelcomeModal: (value: boolean) => {},
  openChangeOrgModal: false,
  setOpenChangeOrgModal: (value: boolean) => {},
};

export const UIContext = createContext<UIProps>(DEFAULT_PROPS);

export const useUiState = () => useContext(UIContext);

interface Props {
  children: React.ReactNode;
}

export const UIProvider: NextPage<Props> = ({ children }) => {
  const { isSignedIn } = useAuth();
  const { organization } = useOrganization();
  const [openMenu, setOpenMenu] = useState<boolean>(true);
  const [menuWidth, setMenuWidth] = useState<number>(250);
  const [showMenuItems, setShowMenuItems] = useState<boolean>(true);
  const [showLogo, setShowLogo] = useState<boolean>(true);
  const [duration] = useState<number>(500);
  const [openMenuWidth] = useState<number>(250);
  const [closedMenuWidth] = useState<number>(80);
  const [snackbarProps, setSnackbarProps] = useState<Toast>({ type: undefined, message: '', open: false, customIcon: false });
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(true);
  const [isExportOpen, setIsExportOpen] = useState<boolean>(false);
  const [isExportDropdownOpen, setIsExportDropdownOpen] = useState<boolean>(true);
  const [openTamDrawer, setOpenTamDrawer] = useState<boolean>(false);
  const [openPersonaDefinitionDrawer, setOpenPersonaDefinitionDrawer] = useState<boolean>(false);
  const [openExportHistoryDrawer, setOpenExportHistoryDrawer] = useState<boolean>(false);
  const [openCustomInsightsDrawer, setOpenCustomInsightsDrawer] = useState<boolean>(false);
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);
  const [searchBarHeight, setSearchBarHeight] = useState<number>(0);
  const [openUpdatesSummaryDrawer, setOpenUpdatesSummaryDrawer] = useState<boolean>(false);
  const [openColumnsDrawer, setOpenColumnsDrawer] = useState<boolean>(false);
  const [openPeopleDrawer, setOpenPeopleDrawer] = useState<boolean>(false);
  const [filtersBarHeight, setFiltersBarHeight] = useState<number>(0);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState<boolean>(false);
  const [openWelcomeModal, setOpenWelcomeModal] = useState<boolean>(false);
  const [openChangeOrgModal, setOpenChangeOrgModal] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if (openMenuWidth && menuWidth) {
      setIsSideMenuOpen(menuWidth === openMenuWidth);
    }
  }, [menuWidth, openMenuWidth]);

  useEffect(() => {
    if (isSignedIn && !organization) {
      setOpenOnboardingModal(true);
    }
  }, [organization, isSignedIn]);

  useEffect(() => {
    if (router.query.session_id) {
      setOpenWelcomeModal(true);
    }
  }, [router.query]);

  const displayToast = (message: string, type: AlertColor, customIcon?: boolean) => {
    setSnackbarProps({ open: true, message: message, type: type, customIcon: customIcon });
  };

  return (
    <UIContext.Provider
      value={{
        openMenu,
        setOpenMenu,
        menuWidth,
        setMenuWidth,
        duration,
        openMenuWidth,
        showMenuItems,
        setShowMenuItems,
        showLogo,
        setShowLogo,
        displayToast,
        snackbarProps,
        setSnackbarProps,
        closedMenuWidth,
        isSideMenuOpen,
        isExportOpen,
        setIsExportOpen,
        isExportDropdownOpen,
        setIsExportDropdownOpen,
        openTamDrawer,
        setOpenTamDrawer,
        openPersonaDefinitionDrawer,
        setOpenPersonaDefinitionDrawer,
        openExportHistoryDrawer,
        setOpenExportHistoryDrawer,
        toolbarHeight,
        setToolbarHeight,
        searchBarHeight,
        setSearchBarHeight,
        openCustomInsightsDrawer,
        setOpenCustomInsightsDrawer,
        openUpdatesSummaryDrawer,
        setOpenUpdatesSummaryDrawer,
        openColumnsDrawer,
        setOpenColumnsDrawer,
        filtersBarHeight,
        setFiltersBarHeight,
        openPeopleDrawer,
        setOpenPeopleDrawer,
        openSettingsModal,
        setOpenSettingsModal,
        openOnboardingModal,
        setOpenOnboardingModal,
        openWelcomeModal,
        setOpenWelcomeModal,
        openChangeOrgModal,
        setOpenChangeOrgModal,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
